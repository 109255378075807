import jQuery from "jquery";
const $ = jQuery; // ドルマークに参照を代入(慣習的な $ を使うため)

/*
 * 確認用
*/

function checkUpdateTime() {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  const timeString = `${hours}:${minutes}:${seconds}`;
  document.getElementById('checktime').textContent = timeString;
}

// 初回表示
checkUpdateTime();

// 1秒ごとに時間を更新
setInterval(checkUpdateTime, 1000);



/*
 * デザイン
*/

function updateTime() {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  const timeString = `${hours}:${minutes}:${seconds}`;
  document.getElementById('time').textContent = timeString;

  // カウントダウン音を再生
  if (now.getSeconds() === 56) {
    playCountdownSound();
  }
}

function playCountdownSound() {
  console.log('playCountdownSound');
  const audio = new Audio('countdown.mp3');
  audio.play();
}

// 初回表示
updateTime();

// 1秒ごとに時間を更新
setInterval(updateTime, 1000);
